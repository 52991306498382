import React, { useState, useEffect } from 'react';
import '../../styles/inventory_style/InventoryModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';

const InventoryModule = () => {
  const [items, setItems] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsResponse, providersResponse] = await Promise.all([
          api.get('/items/'),
          api.get('/providers/')
        ]);
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);
        setProviders(providersResponse.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = async () => {
    if (selectedRow !== null) {
      try {
        const response = await api.get(`/items/${items[selectedRow].id}/`);
        setEditingItem(response.data);
        setIsEditing(true);
      } catch (error) {
        console.error('Error fetching item details:', error);
      }
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingItem({ ...editingItem, [name]: value });
  };

  const handleSave = async () => {
    if (editingItem) {
      try {
        let response;
        if (isCreating) {
          response = await api.post('/items/', editingItem);
        } else {
          response = await api.put(`/items/${editingItem.id}/`, editingItem);
        }
        const updatedItem = response.data;
        
        const itemsResponse = await api.get('/items/');
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);

        setIsEditing(false);
        setIsCreating(false);
        setEditingItem(null);
        setError('');

        setConfirmationMessage(`Item ${updatedItem.item_name} ${isCreating ? 'created' : 'edited'}.`);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error saving item:', error);
        setError('An error occurred while saving the item. Please try again.');
      }
    }
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      setDeletingItem(items[selectedRow]);
      setIsDeleting(true);
    }
  };

  const confirmDelete = async () => {
    if (deletingItem) {
      try {
        await api.post(`/items/${deletingItem.id}/deactivate/`);
        
        const itemsResponse = await api.get('/items/');
        setItems(itemsResponse.data);
        setFilteredItems(itemsResponse.data);

        setIsDeleting(false);
        setDeletingItem(null);
        setSelectedRow(null);
        setShowConfirmation(true);
        setConfirmationMessage(`Item ${deletingItem.item_name} deleted.`);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleCreate = () => {
    setEditingItem({
      part_id: '',
      item_name: '',
      quantity: 0,
      price: '',
      id_provider: '',
    });
    setIsCreating(true);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = items.filter(item => 
      Object.values(item).some(value => 
        value.toString().toLowerCase().includes(term)
      )
    );

    setFilteredItems(filtered);
  };

  const getProviderName = (id) => {
    const provider = providers.find(p => p.id === id);
    return provider ? provider.provider_name : 'Unknown';
  };

  return (
    <div className="inventory-module-container">
      <Sidebar userType="admin" />
      <div className="inventory-module">
        <h1>Inventory Module</h1>
        <div className="search-bar">
          <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearch} />
          <button className="delete-btn" onClick={handleDelete}>🗑️</button>
          <button className="edit-btn" onClick={handleEdit}>✏️</button>
          <button className="add-btn" onClick={handleCreate}>+</button>
        </div>
        {loading ? (
          <p>Loading inventory...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <table className="inventory-table">
            <thead>
              <tr>
                <th>Part ID</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Provider</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((item, index) => (
                <tr 
                  key={item.id} 
                  onClick={() => handleRowClick(index)}
                  className={selectedRow === index ? 'selected' : ''}
                >
                  <td>{item.part_id}</td>
                  <td>{item.item_name}</td>
                  <td>{item.quantity}</td>
                  <td>{getProviderName(item.id_provider)}</td>
                  <td>${parseFloat(item.price).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create Item' : 'Edit Item'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>Part ID</label>
                <input
                  type="text"
                  name="part_id"
                  value={editingItem.part_id}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Item Name</label>
                <input
                  type="text"
                  name="item_name"
                  value={editingItem.item_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={editingItem.quantity}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Provider</label>
                <select
                  name="id_provider"
                  value={editingItem.id_provider}
                  onChange={handleEditChange}
                >
                  <option value="">Select a provider</option>
                  {providers.map(provider => (
                    <option key={provider.id} value={provider.id}>
                      {provider.provider_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Price</label>
                <input
                  type="number"
                  step="0.01"
                  name="price"
                  value={editingItem.price}
                  onChange={handleEditChange}
                />
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingItem(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete {deletingItem.item_name}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>{confirmationMessage}</p>
            <button className="ok-btn" onClick={handleConfirmationClose}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryModule;