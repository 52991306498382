import React, { useState, useEffect } from 'react';
import '../../styles/providers_module_style/ProvidersModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';

const ProvidersModule = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingProvider, setEditingProvider] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingProvider, setDeletingProvider] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/providers/');
        setProviders(response.data);
        setFilteredProviders(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = async () => {
    if (selectedRow !== null) {
      try {
        const response = await api.get(`/providers/${providers[selectedRow].id}/`);
        setEditingProvider(response.data);
        setIsEditing(true);
      } catch (error) {
        console.error('Error fetching provider details:', error);
      }
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingProvider({ ...editingProvider, [name]: value });
  };

  const handleSave = async () => {
    if (editingProvider) {
      try {
        let response;
        if (isCreating) {
          response = await api.post('/providers/', editingProvider);
        } else {
          response = await api.put(`/providers/${editingProvider.id}/`, editingProvider);
        }
        const updatedProvider = response.data;
        
        // Fetch the updated list of providers
        const providersResponse = await api.get('/providers/');
        setProviders(providersResponse.data);
        setFilteredProviders(providersResponse.data);

        setIsEditing(false);
        setIsCreating(false);
        setEditingProvider(null);
        setError('');

        // Set the confirmation message and show the popup
        setConfirmationMessage(`Provider ${updatedProvider.provider_name} ${isCreating ? 'created' : 'edited'}.`);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error saving provider:', error);
        setError('An error occurred while saving the provider. Please try again.');
      }
    }
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      setDeletingProvider(providers[selectedRow]);
      setIsDeleting(true);
    }
  };

  const confirmDelete = async () => {
    if (deletingProvider) {
      try {
        await api.post(`/providers/${deletingProvider.id}/deactivate/`);
        
        // Fetch the updated list of providers
        const providersResponse = await api.get('/providers/');
        setProviders(providersResponse.data);
        setFilteredProviders(providersResponse.data);

        setIsDeleting(false);
        setDeletingProvider(null);
        setSelectedRow(null);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error deleting provider:', error);
      }
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleCreate = () => {
    setEditingProvider({
      provider_name: '',
      email: '',
      contact_number: '',
      address: '',
    });
    setIsCreating(true);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = providers.filter(provider => 
      Object.values(provider).some(value => 
        value.toString().toLowerCase().includes(term)
      )
    );

    setFilteredProviders(filtered);
  };

  return (
    <div className="providers-module-container">
      <Sidebar userType="admin" />
      <div className="providers-module">
        <h1>Providers Module</h1>
        <div className="search-bar">
          <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearch} />
          <button className="delete-btn" onClick={handleDelete}>🗑️</button>
          <button className="edit-btn" onClick={handleEdit}>✏️</button>
          <button className="add-btn" onClick={handleCreate}>+</button>
        </div>
        {loading ? (
          <p>Loading providers...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <table className="providers-table">
            <thead>
              <tr>
                <th>Provider Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {filteredProviders.map((provider, index) => (
                <tr 
                  key={provider.id} 
                  onClick={() => handleRowClick(index)}
                  className={selectedRow === index ? 'selected' : ''}
                >
                  <td>{provider.provider_name}</td>
                  <td>{provider.email}</td>
                  <td>{provider.contact_number}</td>
                  <td>{provider.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create Provider' : 'Edit Provider'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>Provider Name</label>
                <input
                  type="text"
                  name="provider_name"
                  value={editingProvider.provider_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={editingProvider.email}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Contact Number</label>
                <input
                  type="text"
                  name="contact_number"
                  value={editingProvider.contact_number}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={editingProvider.address}
                  onChange={handleEditChange}
                />
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingProvider(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete {deletingProvider.provider_name}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>{confirmationMessage}</p>
            <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProvidersModule;
