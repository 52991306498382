import React from 'react';
import '../../styles/home_style/Home.scss';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import icon1 from '../../assets/images/icon_user_module.svg';
import icon2 from '../../assets/images/icon_clients_module.svg';
import icon3 from '../../assets/images/icon_machinery_module.svg';
import icon4 from '../../assets/images/icon_job_module.svg';
import icon5 from '../../assets/images/icon_inventory_module.svg';
import icon6 from '../../assets/images/icon_providers_module.svg';
import icon7 from '../../assets/images/icon_purchase_order_module.svg';

function Home() {
  const userType = 'admin';

  return (
    <div className="home-container">
      <Sidebar userType={userType} />
      <div className="home-content">
        <h1>Welcome [admin]</h1>

        <div className="modules-grid">
          <Link to="/user" className="module-card">
            <img src={icon1} alt="User Module Icon" className="module-icon" />
            <span className="module-name">User Module</span>
          </Link>
          <Link to="/clients" className="module-card">
            <img src={icon2} alt="Clients Module Icon" className="module-icon" />
            <span className="module-name">Clients Module</span>
          </Link>
          <Link to="/machinery" className="module-card">
            <img src={icon3} alt="Machinery Module Icon" className="module-icon" />
            <span className="module-name">Machinery Module</span>
          </Link>
          <Link to="/job" className="module-card">
            <img src={icon4} alt="Job Module Icon" className="module-icon" />
            <span className="module-name">Job Module</span>
          </Link>
          <Link to="/inventory" className="module-card">
            <img src={icon5} alt="Inventory Icon" className="module-icon" />
            <span className="module-name">Inventory</span>
          </Link>
          <Link to="/providers" className="module-card">
            <img src={icon6} alt="Providers Icon" className="module-icon" />
            <span className="module-name">Providers</span>
          </Link>
          <Link to="/purchase-order" className="module-card">
            <img src={icon7} alt="Purchase Order Icon" className="module-icon" />
            <span className="module-name">Purchase Order</span>
          </Link>
        </div>
        
      </div>
    </div>
  );
}

export default Home;