import React, { useState, useEffect } from 'react';
import '../../styles/user_module_style/UserModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import axios from 'axios';


const UserModule = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [groups, setGroups] = useState({});

  const fetchGroups = async () => {
    try {
      const response = await api.get('/groups/');
      const groupsData = {};
      response.data.forEach(group => {
        groupsData[group.id] = group.name;
      });
      setGroups(groupsData);
    } catch (err) {
      console.error('Error fetching groups:', err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGroups();
        const response = await api.get('/users');
        setUsers(response.data);
        setFilteredUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleEdit = async () => {
    if (selectedRow !== null) {
      try {
        const response = await api.get(`/users/${users[selectedRow].id}/`);
        setEditingUser(response.data);
        setIsEditing(true);
      } catch (error) {
        console.error('Error fetching user details:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  const PasswordRequirements = ({ requirements }) => (
    <div className="password-requirements">
      <p>Password must contain:</p>
      <ul>
        <li className={requirements.length ? 'met' : ''}>At least 8 characters</li>
        <li className={requirements.uppercase ? 'met' : ''}>1 uppercase letter</li>
        <li className={requirements.lowercase ? 'met' : ''}>1 lowercase letter</li>
        <li className={requirements.number ? 'met' : ''}>1 number</li>
        <li className={requirements.specialChar ? 'met' : ''}>1 special character</li>
      </ul>
    </div>
  );

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === 'groups') {
      setEditingUser({ ...editingUser, [name]: [parseInt(value, 10)] });
    } else {
      setEditingUser({ ...editingUser, [name]: value });
    }

    if (name === 'password') {
      const requirements = {
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /\d/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
      };
      setPasswordRequirements(requirements);
    }
  };

  const handleSave = async () => {
    if (editingUser) {
      try {
        let response;
        let dataToSend = { ...editingUser };
        if (!isCreating) {
          delete dataToSend.password;
        }
        if (isCreating) {
          response = await api.post('/users/', dataToSend);
        } else {
          console.log("editing");
          console.log(dataToSend);  
          response = await api.put(`/users/${editingUser.id}/`, dataToSend);
        }
        const updatedUser = response.data;
        let updatedUsers;
        if (isCreating) {
          updatedUsers = [...users, updatedUser];
        } else {
          updatedUsers = users.map(user => 
            user.id === updatedUser.id ? updatedUser : user
          );
        }
        setUsers(updatedUsers);
        setIsEditing(false);
        setIsCreating(false);
        setEditingUser(null);
        setError('');
      } catch (error) {
        console.error('Error saving user:', error);
        setError('An error occurred while saving the user. Please try again.');
      }
    }
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      setDeletingUser(users[selectedRow]);
      setIsDeleting(true);
      console.log(deletingUser);
    }
  };

  const confirmDelete = async () => {
    if (deletingUser) {
      try {
        await api.post(`/users/${deletingUser.id}/deactivate/`);
        const updatedUsers = users.filter(user => user.id !== deletingUser.id);
        setUsers(updatedUsers);
        setIsDeleting(false);
        setDeletingUser(null);
        setSelectedRow(null);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error deleting user:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleCreate = () => {
    setEditingUser({
      username: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      groups: [],
      user_permissions: []
    });
    setIsCreating(true);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = users.filter(user => 
      Object.values(user).some(value => 
        value.toString().toLowerCase().includes(term)
      )
    );

    setFilteredUsers(filtered);
  };

  return (
    <div className="user-module-container">
      <Sidebar userType="admin" />
      <div className="user-module">
        <h1>User Module</h1>
        <div className="search-bar">
          <input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearch} />
          <button className="delete-btn" onClick={handleDelete}>🗑️</button>
          <button className="edit-btn" onClick={handleEdit}>✏️</button>
          <button className="add-btn" onClick={handleCreate}>+</button>
        </div>
        {loading ? (
          <p>Loading users...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <table className="user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Last name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                 user.groups.length > 0 && groups[user.groups[0]] && (
                  <tr 
                    key={user.id} 
                    onClick={() => handleRowClick(index)}
                    className={selectedRow === index ? 'selected' : ''}
                  >
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td><span className="role-badge">{groups[user.groups[0]] || 'Unknown'}</span></td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        )}
      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create User' : 'Edit User'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={editingUser.first_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={editingUser.last_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  value={editingUser.username}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={editingUser.email}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={editingUser.password || ''}
                onChange={handleEditChange}
              />
              <PasswordRequirements requirements={passwordRequirements} />
            </div>
              <div className="form-group">
                <label>Role</label>
                <select
                  name="groups"
                  value={editingUser.groups[0] || ''}
                  onChange={handleEditChange}
                >
                  <option value="">Select a role</option>
                  {Object.entries(groups).map(([id, name]) => (
                    <option key={id} value={parseInt(id, 10)}>{name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingUser(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete {deletingUser.first_name} {deletingUser.last_name}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>User deleted from system</p>
            <button className="ok-btn" onClick={handleConfirmationClose}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserModule;