import React, { useState } from 'react';
import '../../styles/machinery_module_style/MachineryModule.scss';
import Sidebar from '../../components/Sidebar';

const MachineryModule = () => {
    const [machineries, setMachines] = useState([
        { unit: 'Machinery1', module: 'Model1', serial_num: 'ABCD12345', client: 'Client1', location: 'Location1' },
        { unit: 'Machinery2', module: 'Model1', serial_num: 'ABCD12346', client: 'Client2', location: 'Location2' },
        { unit: 'Machinery3', module: 'Model2', serial_num: 'ABCD12347', client: 'Client3', location: 'Location3' },
    ]);

    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (index) => {
        setSelectedRow(index === selectedRow ? null : index);
    };
  
    return (
        <div className="machinery-module-container">
            <Sidebar userType="admin" />
            <div className="machinery-module">
            <h1>Machinery Module</h1>
            <div className="search-bar">
                <input type="text" placeholder="Search..." />
                <button className="delete-btn">🗑️</button>
                <button className="edit-btn">✏️</button>
                <button className="add-btn">+</button>
            </div>
            <table className="machine-table">
                <thead>
                <tr>
                    <th>Machinery unit</th>
                    <th>Module</th>
                    <th>Serial Number</th>
                    <th>Client</th>
                    <th>Location</th>
                </tr>
                </thead>
                <tbody>
                {machineries.map((machine, index) => (
                    <tr 
                    key={index} 
                    onClick={() => handleRowClick(index)}
                    className={selectedRow === index ? 'selected' : ''}
                    >
                    <td>{machine.unit}</td>
                    <td>{machine.module}</td>
                    <td>{machine.serial_num}</td>
                    <td>{machine.client}</td>
                    <td>{machine.location}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default MachineryModule;





