import React, { useState } from 'react';
import '../../styles/login_style/Login.scss';
import liftcoLogo from '../../assets/images/liftco_logo.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      console.log('Submitting login form');
      const response = await api.post('/api/token/', { username, password });
      if (response.status === 200) {
        const data = response.data;
        console.log('Login successful', data);
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);

        navigate('/home');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={liftcoLogo} alt="LIFTCO Logo" className="liftco-logo" />
        <form onSubmit={handleSubmit}>
          <div className="login-input-group">
            <div className="input-group">
              <label htmlFor="username">Username</label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button">Login</button>
        </form>
        <Link to="/reset-account" className="forgot-password">Forgot my password</Link>
      </div>
    </div>
  );
}

export default Login;