import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Sidebar.scss';
import icon0 from '../assets/images/icon_home.svg';
import icon1 from '../assets/images/icon_user_module.svg';
import icon2 from '../assets/images/icon_clients_module.svg';
import icon3 from '../assets/images/icon_machinery_module.svg';
import icon4 from '../assets/images/icon_job_module.svg';
import icon5 from '../assets/images/icon_inventory_module.svg';
import icon6 from '../assets/images/icon_providers_module.svg';
import icon7 from '../assets/images/icon_purchase_order_module.svg';

const Sidebar = ({ userType }) => {
  const modules = [
    { icon: icon0, name: 'Home', path: '/home', allowedUsers: ['admin', 'manager', 'technician'] },
    { icon: icon1, name: 'User Module', path: '/user', allowedUsers: ['admin'] },
    { icon: icon2, name: 'Clients Module', path: '/clients', allowedUsers: ['admin', 'manager'] },
    { icon: icon3, name: 'Machinery Module', path: '/machinery', allowedUsers: ['admin', 'manager', 'technician'] },
    { icon: icon4, name: 'Job Module', path: '/job', allowedUsers: ['admin', 'manager', 'technician'] },
    { icon: icon5, name: 'Inventory', path: '/inventory', allowedUsers: ['admin', 'manager'] },
    { icon: icon6, name: 'Providers', path: '/providers', allowedUsers: ['admin', 'manager'] },
    { icon: icon7, name: 'Purchase Order', path: '/purchase-order', allowedUsers: ['admin', 'manager'] },
  ];

  return (
    <div className="sidebar">
      {modules.map((module, index) => (
        module.allowedUsers.includes(userType) && (
          <Link key={index} to={module.path} className="sidebar-icon">
            <img src={module.icon} alt={module.name} />
            <span>{module.name}</span>
          </Link>
        )
      ))}
    </div>
  );
};

export default Sidebar;
